<template>
  <b-card-code class="h-100">
      <b-row class="py-1 w-100 d-flex justify-content-between mb-2">
      <b-col md='4'>
        <b-form-input
          v-model="search"
          placeholder="Search by name or amount"
          @input="handleSearch"
        />
      </b-col>
      <b-button size="sm" variant="primary" @click="handleMultiCommission">Restore</b-button>
    </b-row>
    <div >
    </div>
    <div style="width: 100%;height:100%; overflow: auto">
      <table class="table table-bordered" style="min-width: 28.9cm">
        <thead class="text">
          <tr>
            <th>
                <div class="d-flex gap-1">

              <b-form-checkbox v-model="allChecked" @input="handleAll($event)"> </b-form-checkbox>
              Sr. No
              </div></th>
            <th>User Name</th>
            <th>Earning</th>
            <th>TDS</th>
            <th>Admin Charges</th>
            <th>Net Amount</th>
            <th>%</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, id) in commissionList" :key="id">
            <td>
              <div class="d-flex gap-1">

              <b-form-checkbox v-model="item.checked"> </b-form-checkbox>
              {{ id + 1 }}
              </div>
              </td>
            <td>{{item.user?item.user.fullname:''}}</td>
            <td>{{item.earning}}</td>
            <td>{{item.tds_amount}}</td>
            <td>{{item.admin_charges}}</td>
            <td>{{item.net_amount}}</td>
            <td>{{item.per}}</td>
            <td>{{item.status}}</td>
            <td>
              <span class="ml-2">
                <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                  <template v-slot:button-content>
                    <feather-icon width="30" height="30" icon="AlignRightIcon" size="16"
                      class="text-body align-middle mr-25" />
                  </template>
                  <b-dropdown-item @click="handleCommission([item.id])" variant="primary">
                  <b>  Restore</b>
                  </b-dropdown-item>
                </b-dropdown>
              </span></td>

          </tr>
          <tr v-if="commissionList.length == 0">
            <td colspan="9">
              <div class="d-flex justify-content-center w-100">
                  No data found
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import axios from '@/components/axios';
import moment from "moment";
import { BFormCheckbox,BDropdown,BDropdownItem, BButton,BFormInput,BRow,BCol } from 'bootstrap-vue';
import _ from 'lodash'


export default {
        components:{BCardCode,BFormCheckbox,BDropdown,BDropdownItem,BButton,BFormInput,BRow,BCol},

      data(){
        return{
            accessToken: localStorage.getItem("accessToken"),
            baseApi: process.env.VUE_APP_APIENDPOINT,
            commissionList:[],
            allChecked:false,
            search:'',

        }
      },
      mounted(){
        this.getCommissionList()
      },
      methods:{
        handleAll(e){
          this.commissionList.map((item)=>{
            item.checked = e
          })
        },
          handleSearch: _.debounce(function () {
          this.getCommissionList()

        }, 1000),
        async getCommissionList(){
          await axios({
                method: "GET",
                url: `${this.baseApi}/getCommissionList/Block`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.accessToken}`,
                },
                params:{search:this.search}

              })
                .then((json) => {
                  this.commissionList= json.data.data
                })
                .catch((error) => console.log(error, "error"));
        },
        async handleMultiCommission(){
          let selected = this.commissionList.filter(item=>item.checked)
          selected =selected.map(item=>item.id)
          if(selected.length == 0){
            this.$swal({
              icon: "warning",
              title: "Warning",
              text: "Please select atleast one!",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
          else{
            this.handleCommission(selected)
          }
        },
        async handleCommission(ids){
          let data={
            ids:ids,
            status:'Unpaid',
          }
          await axios({
              method: "POST",
              url: `${this.baseApi}/chageCommissionStatus`,
              data:data,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then((json) => {
                this.$swal({
                  icon: "success",
                  title: "Success!",
                  text: "Restored Successfully Saved",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.getCommissionList()
              })
              .catch((error) => {
                console.log(error, "error")
                this.$swal({
                  icon: "error",
                  title: "Error!",
                  text: "Something went wrong",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                });
        }

      }
}
</script>
<style>
  .gap-1{
    gap:10px
  }
</style>
